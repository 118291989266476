import Vue from "vue";
import {
  LiffListConfigInterface,
  TableDataType,
} from "@/components/list/types/list";
import orderTab from "@/modules/base/config/storeOrderTab";
class listConfig {
  protected vm?: Vue;
  protected listKey?: string;

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm;
    this.listKey = listKey;

    return {
      authType: "read",
      searchFilterOrder: 0,
      pageTitle: "module.orderAllProviders",
      search: "store.order.search_keyword",
      sort: [{ label: "data.created_at", key: "created_at" }],
      liffFilter: {
        created_at: {
          label: "data.created_at",
          type: "date",
        },
        status: {
          type: "tab",
          options: () => {
            return orderTab;
          },
        },
      },
      listItem: () =>
        import("@/modules/store/views/orders/components/orderCardItem.vue"),
      useRow: false,
    };
  }
}

export default new listConfig();
